import React from 'react';

import BlogArticle from 'components/blog/blogArticle';
import ImageModal from 'components/ui/image-modal';
import { authorKamil as author } from 'data/authors';

import img from 'img/blog/updates/movement_trace.png';

const Content = () => {
  return (
    <div>
      <p>
        From now, you can enable to draw a movement trace during session playback. Just click on
        &quot;Movement trace&quot; button in controls panel to turn on this feature.
      </p>
      <ImageModal
        src={img}
        alt="LiveSession App - movement"
        title="Enable to draw a movement trace during session playback"
      />
      <p className="mt-3" />
    </div>
  );
};

const Wrapped = BlogArticle(Content);

export const frontmatter = {
  title: 'Mouse movement trace',
  url: '/blog/mouse-movement-trace/',
  description: "See user's mouse movement trace",
  author,
  img,
  date: '2019-05-18',
  category: 'Product Updates',
  group: 'updates',
};

export default () => (
  <Wrapped
    title={frontmatter.title}
    category={frontmatter.category}
    description={frontmatter.description}
    date={frontmatter.date}
    author={frontmatter.author}
    url={frontmatter.url}
    group={frontmatter.group}
  />
);
